import React from 'react';
import "./cardInfo.scss";

function CardInfo(props) {
    return (
        <div className='card-info'>
            <div className="card-info__content">
                <h4>{props.title}</h4>
                <div dangerouslySetInnerHTML={{ __html: props.desc }}>
                </div>

            </div>
        </div>
    );
}

export default CardInfo;