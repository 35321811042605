import React from 'react';
import "./stickyButton.scss";
import buttonExternalIcon from "../../images/icons/external-icon.svg";
import ExternalLink from '../modals/externalLink';


function StickyButton() {
    return (
        <div className="sticky-wrapper">
            <ExternalLink href="https://www.qutenza.com/find-a-qutenza-specialist/">
                <div className='sticky-button'>
                    <div className="icon">
                        <img src={buttonExternalIcon} alt="icon" />
                    </div>
                    <span>
                        Find a Specialist
                    </span>
                </div>
            </ExternalLink>
        </div>
    );
}

export default StickyButton;